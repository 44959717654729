<template>
  <div class="d-flex flex-column align-items-center">

    <b-card style="min-width: 400px;" class="mt-16" v-if="view_status === 'pending'">
      <div class="d-flex justify-content-center mb-8 mt-8">
        <span class="text-center">{{ $t('PUBLIC.PAYMENT.WAITING') }}</span>
      </div>
      
      <div class="d-flex justify-content-center mb-8 mt-8">
        <b-spinner label="Loading..."></b-spinner>
      </div>
    </b-card>

    <b-card style="min-width: 400px;" class="mt-16 mb-32" v-else-if="view_status === 'paid'">
      <div class="col-12 text-center">
        <i class="fa fa-check-circle" style="font-size: 42px; color: #8dda8d;"></i>
      </div>

      <div class="d-flex justify-content-center mb-8 mt-8">
        <span class="text-center" style="font-weight: bold;">{{ $t('PUBLIC.PAYMENT.PAYMENT_SUCCESS') }}</span>
      </div>
  
    </b-card>

    <b-card style="min-width: 400px;" class="mt-16 mb-32" v-else-if="view_status === 'fail'">
      <div class="col-12 text-center">
        <i class="fa fa-minus-circle" style="font-size: 42px; color: #fe0000;"></i>
      </div>

      <div class="d-flex justify-content-center mb-8 mt-8">
        <span class="text-center" style="font-weight: bold;">{{ $t('PUBLIC.PAYMENT.PAYMENT_FAILED') }}</span>
      </div>

      <div class="d-flex justify-content-center mb-8 mt-8">
        <span class="text-center">{{ $t('PUBLIC.PAYMENT.TRY_LATER') }}</span>
      </div>
  
    </b-card>

  </div>
</template>

<script>
import axios from 'axios';
import PaymentComplete from '@/view/components/PaymentComplete.vue';
import PaymentOrderDetails from '@/view/components/PaymentOrderDetails.vue';
import PendingPaymentInfo from '@/view/components/PendingPaymentInfo.vue';

import { setKeyValue, getKeyValue } from '@/core/services/simpleStore';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';
import { isPlugin } from "@/core/services/messageParent";

export default {
  name: 'PaymentStatusComponent',
  props: ['order'],
  mixins: [ toasts ],
  components: {
    PaymentComplete,
    PaymentOrderDetails,
    PendingPaymentInfo
  },
  computed: {

    min_width() {
      return {
        'min-width': is_mobile()  ? '460px' : '640px'
      };
    },

    is_mobile() {
      return !is_mobile();
    },

    view_status() {
      switch (this.order_status) {
        case 'NONE':
        case 'CREATED':
        case 'WAIT_PAYMENT':
          return 'pending';
        case 'DONE':
        case 'PAID_IN_FULL':
          return 'paid';
        default:
          return 'fail';
      }
    }
    
  },
  data() {
    return {
      local_order: null,
      interval_id: null,

      order_status: 'WAIT_PAYMENT'

    };
  },
  mounted() {

    if (this.order) {
      this.local_order = { ...this.order }

      this.start_polling();
    }

  },
  watch: {
    
    order: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        this.local_order = { ...this.order };
      }
    },
  
    shop_order_id() {
      if (this.shop_order_id) {
        this.local_shop_order_id = this.shop_order_id;

        this.start_polling();
      }
    },

  },
  methods: {
    
    start_polling() {
      if (this.interval_id) {
        clearInterval(this.interval_id);
      }

      this.interval_id = setInterval(()=>{
        this.poll_shop_order();
      }, 1000);
    },

    async get_order(token, shop_order_id) {
      try {
        const res = await axios.get(`/shop_order/token/${token}/${shop_order_id}`);

        if (res.status === 200) {
          return res.data;
        }
      }
      catch (err) {
        console.error('get_order error', err);
      }

      throw `unable to get order`;
    },

    redirect_url(order) {
      
      if (order && order.company && order.company.company_settings && order.company.company_settings.post_register_url) { 
        let url = order.company.company_settings.post_register_url;

        if (!url.match(/^https?:\/\//)) {
          url = 'http://' + url;
        }

        setTimeout(()=>{
          messageParent(JSON.stringify({ redirect: url }));

          if (!isPlugin()) {
            window.location.href = url;
          }
        }, 2000);
      }
    },

    async poll_shop_order() {
      try {
        const item = await this.get_order(this.local_order.token, this.local_order.shop_order_id);

        if (item) {
          this.local_order = { ...item };

          this.order_status = this.local_order.status;

          switch (this.local_order.status) {
            case 'PAID_IN_FULL':
            case 'DONE': {
              this.stop_poll_shop_order();
              this.redirect_url(this.local_order);
              break;
            }
            case 'DELETED':
            case 'FAILED': 
            case 'CANCELLED': {
              this.stop_poll_shop_order();
              break;
            }
          }
        }
      }
      catch (err) {
        console.error('poll_shop_order error', err);
      }
    },

    stop_poll_shop_order() {
      if (this.interval_id) {
        clearInterval(this.interval_id);
      }
    },
  }
};
</script>
